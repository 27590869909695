// 最新路由
export default [
  {
    path: "/createProject",
    name: "detail",
    component: () => import("@/page/home/createProject.vue"),
    meta: {
      title: "免费试用",
      requiresAuth: false,
    },
  },
  {
    path: "/appPlatform",
    name: "appPlatform",
    component: () => import("@/page/home/appPlatform.vue"),
    meta: {
      title: "应用平台",
      requiresAuth: false,
      hideMobileHeader: true,
      hiddenFooter: true,
    },
  },
  {
    path: "/czyApi",
    name: "czyApi",
    component: () => import("@/page/developer/czyApi/index.vue"),
    meta: {
      title: "应用平台",
      requiresAuth: false,
      hideMobileHeader: true,
      hiddenFooter: true,
    },
  },
  {
    path: "/upgradePlan",
    name: "UpgradePlan",
    component: () => import("@/page/home/tenantry/index.vue"),
    meta: {
      title: "租户升级",
      requiresAuth: false,
      hiddenFooter: true,
    },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/page/developer/RouteBase.vue"),
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/page/developer/DeveloperDashboard.vue"),
        meta: {
          title: "开发者后台",
          hiddenFooter: true,
        },
      },
      {
        path: ":id/base",
        name: "BaseInfo",
        component: () => import("@/page/developer/BaseInfo.vue"),
        meta: {
          title: "租户升级",
          requiresAuth: false,
          hiddenFooter: true,
        },
      },
      {
        path: ":topicType/:topicId/om",
        name: "OperationMonitoring",
        component: () => import("@/page/developer/OperationMonitoring.vue"),
        meta: {
          title: "运营监控",
          hiddenFooter: true,
        },
      },
    ],
  },
  {
    path: "/suchPerson",
    name: "suchPerson",
    component: () => import("@/page/splendidczy/SuchPerson.vue"),
    meta: {
      title: "我们这样一群人",
      hiddenFooter: false,
    },
  },
  {
    path: "/aboutCZY",
    name: "AboutCZY",
    component: () => import("@/page/splendidczy/aboutCZY.vue"),
    meta: {
      title: "关于炽橙",
      hiddenFooter: false,
    },
  },

  {
    path: "/handbook",
    name: "handbook",
    component: () => import("@/page/home/aiHandbook/index.vue"),
    meta: {
      title: "AI手册",
      requiresAuth: false,
      hiddenFooter: true,
    },
  },
  {
    path: "/chooseCZY",
    name: "chooseCZY",
    component: () => import("@/page/home/chooseCZY/index.vue"),
    meta: {
      title: "选择超真云",
      hiddenFooter: false,
    },
  },
  {
    path: "/schema",
    name: "Schema",
    component: () => import("@/page/schema/index.vue"),
    meta: {
      hiddenFooter: false,
    },
  },
  {
    path: "/czy-serve",
    name: "czy-serve",
    component: () => import("@/page/home/serve/index.vue"),
    meta: {
      title: "服务支持",
      hiddenFooter: false,
    },
  },
  {
    path: "/newMineHome",
    name: "newMineHome",
    component: () => import("@/page/home/newMineHome/index.vue"),
    meta: {
      title: "个人中心",
      requiresAuth: true,
      hiddenFooter: true,
    },
  },
  // 模型平台
  {
    path: "/modelPlat",
    name: "modelPlat",
    component: () => import("@/page/modelPlat/index.vue"),
    meta: {
      title: "模型平台",
      // hiddenNav: true,
      hiddenFooter: true,
    },
  },
  {
    path: "/modelPlat-detail",
    name: "/modelPlat-detail",
    component: () => import("@/page/home/appPlatform/appDialog.vue"),
    meta: {
      title: "模型详情",
      // hiddenNav: true,
      hiddenFooter: true,
    },
  },
  {
    path: "/newsDetail",
    name: "NewsDetail",
    component: () => import("@/page/splendidczy/newsDetail.vue"),
    meta: {
      title: "新闻资讯",
      hiddenFooter: false,
    },
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("@/page/schema/products.vue"),
    meta: {
      title: "产品与解决方案",
    },
  },
  {
    path: "/MobileApps",
    name: "MobileApps",
    component: () => import("@/page/mobileComponent/MobileApps.vue"),
    meta: {
      title: "应用精选",
      hiddenFooter: true,
      smallHeader: true,
    },
  },
  {
    path: "/MobileNews",
    name: "MobileNews",
    component: () => import("@/page/mobileComponent/MobileNews.vue"),
    meta: {
      title: "新闻资讯",
      hiddenFooter: true,
    },
  },
  {
    path: "/LinkView",
    name: "LinkView",
    component: () => import("@/page/mobileComponent/LinkView.vue"),
    meta: {
      title: "预览",
      hiddenFooter: true,
      hiddenNav: true,
    },
  },
  {
    path: "/MobileHome",
    name: "MobileHome",
    component: () => import("@/page/mobileComponent/MobileHome.vue"),
    meta: {
      title: "首页",
      hiddenFooter: true,
    },
  },
  {
    path: "/MobileUser",
    name: "MobileUser",
    component: () => import("@/page/mobileComponent/MobileUser.vue"),
    meta: {
      title: "用户中心",
      hiddenFooter: true,
      requiresAuth: true,
    },
  },
  // 下面都是宣传页
  {
    path: "/user-member",
    name: "user-member",
    component: () => import("@/page/home/commercialization/member/member.vue"),
    meta: {
      title: "会员中心",
      hiddenFooter: false,
    },
  },
  {
    path: "/firm-service",
    name: "firm-service",
    component: () => import("@/page/home/commercialization/service/index.vue"),
    meta: {
      title: "企业服务",
      hiddenFooter: false,
    },
  },
  {
    path: "/firm-service2",
    name: "firm-service",
    component: () => import("@/page/home/commercialization/service2/index.vue"),
    meta: {
      title: "企业服务",
      hiddenFooter: false,
    },
  },
  {
    path: "/memberDialog",
    name: "memberDialog",
    component: () =>
      import("@/page/home/commercialization/member/memberDialog.vue"),
    meta: {
      title: "会员购买",
      hiddenNav: true,
      hiddenFooter: true,
    },
  },
  {
    path: "/modelingService",
    name: "modelingService",
    component: () =>
      import("@/page/home/commercialization/modelingService/index.vue"),
    meta: {
      title: "建模服务",
      hiddenFooter: false,
    },
  },
  {
    path: "/campus",
    name: "campus",
    component: () => import("@/page/home/commercialization/campus/index.vue"),
    meta: {
      title: "校园计划",
      hiddenFooter: false,
    },
  },
];
