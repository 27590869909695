<template>
  <div>
    <div class="headerDiv" ref="headNavigator" v-if="!hideHeader" :class="{
      'headerDiv-showContest': isShowContest,
    }">
      <!-- isShowContest -->
      <div class="contest" v-if="isShowContest" :style="{ background: `url(${adObj.bgImageUrl}) round` }">
        <!-- <span @click="$router.push('/contest/hgd')">{{ noticeData.noticeTitle }}</span> -->
        <img :src="adObj.imageUrl" @click="goLinkUrl(adObj.linkUrl)" alt="" srcset="" class="head-vip" />
        <i class="el-icon-close" @click="closeContest"></i>
      </div>

      <div class="FiexedSystemWidthHorizontal">
        <div @click="goMainPage" class="logoDiv">
          <img :src="appLogo" alt="" srcset="" class="chichengLogo" />
        </div>
        <nav-list ref="nav"></nav-list>
        <div class="box">
          <el-popover ref="popover" trigger="hover" popper-class="loginPop" :visible-arrow="arrow">
            <div slot="reference">
              <div style=" cursor: pointer; font-size: 18px">
                <img src="@/assets/noun-download.png" alt="" srcset="" class="noun-download" />
              </div>
            </div>
            <div class="download-content">
              <img src="@/assets/shouce.png" style="margin-left: 30px" alt="" srcset="" />
              <div>
                <div class="left-content"><img src="@/assets/wns.png" alt="" srcset="" />Windows 版本</div>
                <div class="download-btn">
                  <a href="https://oss.czy3d.com/cz-editor/electron/%E8%B6%85%E7%9C%9F%E4%BA%91%E6%89%8B%E5%86%8C.zip"
                    @click="getDownload('下载Windows版本超真云手册')">下载</a>
                </div>

              </div>
              <img src="@/assets/robolink.png" style="margin-left: 30px;margin-top: 20px;" alt="" srcset="" />
              <div>
                <div class="left-content"><img src="@/assets/wns.png" alt="" srcset="" />Windows 版本</div>
                <div class="download-btn">
                  <a :href="robolinkDownloadUrl" @click="getDownload('下载Windows版本robolink')">下载</a>
                </div>

              </div>
            </div>
          </el-popover>
          <div
            style="margin-right: 30px; cursor: pointer; font-size: 18px;color: rgba(255, 96, 52, 1);position: relative;width: 140px;">
            <div @click="isShowCad = true"> CAD看图助手</div>
            <div class="cad-card" v-if="isShowCad">
              <img src="@/page/home/commercialization/cad/static/X.png" @click="closeCad()" alt="" srcset="" class="x">
              <div class="cad-card-title">2D图纸和3D模型都能看</div>
              <div class="icon-content">
                <div class="icon-item" v-for="(item, index) in icons" :key="index">
                  <img :src="require(`../../assets/cad/icon${index}.png`)" /><span>{{
                    item
                  }}</span>
                  <img :src="require(`../../assets/cad/icon-check.png`)" class="icon-check" />

                </div>
              </div>
              <div class="cad-box">
                <div class="cad-left"></div>
                <div class="wx-code">
                  <img src="@/page/home/commercialization/cad/static/wx.png" alt="" srcset="" class="qr-code">
                  <div class="wx-desc">使用微信小程序扫码打开</div>
                  <div class="wx-title">移动端打开 使用更便捷</div>
                </div>
              </div>
            </div>
          </div>
          <el-popover v-if="user" placement="bottom-end" ref="popover" trigger="click" popper-class="loginPop"
            :visible-arrow="arrow">
            <div slot="reference" class="userAvatar">
              <div class="userAvatar-left">
                <div class="userAvatar-left-userName">{{ user.username }}</div>
                <div class="userAvatar-left-spaceName">{{ "@" + user.username }}</div>
              </div>
              <img v-if="user.type == 2" src="@/assets/vip-ico3.png" class="vip-avatar" />
              <!-- <div class="vip-avatar">V</div> -->
              <el-image :src="user.avatar" @mouseover="ifShowLogin = true" class="userAvatar avatar">
                <div slot="error" class="image-slot">
                  <img :src="defultHeader" alt="" srcset="" class="userAvatar" />
                </div>
              </el-image>
            </div>
            <LoginBtn></LoginBtn>
          </el-popover>

          <div class="btn-loginBg custom-img" v-else @click="useEngine(1)">登 录</div>
          <div class="btn-bg custom-img" @click="useEngine(2)">{{ !user ? "免费试用" : "创建应用" }}</div>
        </div>
      </div>
      <!-- 支付失败横幅 -->
      <PayFailureBanner class="PayFailureBanner" />
    </div>
    <!-- 移动端隐藏 hideMobileHeader -->
    <MobileHeader class="mobile-header" v-if="!hideMobileHeader && !hideHeader" />
  </div>
</template>

<script>
import { getAdList, pvRecord } from '@/api/ai/index.js'
import { removeUserInfo } from "@/static/js/userInfo";
import { mapState } from "vuex";
import { getUserInfo } from "@/static/js/userInfo";
import NavList from "./navlist/index";
import "@/static/css/header.scss";
import LoginBtn from "./LoginBtn.vue";
import { check, sysNotice } from "@/api/notification";
import PayFailureBanner from "@/views/useEngine/pc/components/PayFailureBanner.vue";
import CreateProject from "@/views/createProject/index.vue";
import { requireAuthBefore } from "@/static/js/authDecorators";
import { isAppleDevice } from "@/static/utils/utils";
import MobileHeader from "@/page/mobileComponent/MobileHeader.vue";
// import { exec } from "../../../node_modules/@types/node/child_process.d.ts";
import { sysConfigByKey } from "@/api/common/params.js";
export default {
  name: "NavBar",
  components: {
    NavList,
    LoginBtn,
    PayFailureBanner,
    CreateProject,
    MobileHeader,
  },
  data: function () {
    return {
      isShowCad: false,
      icons: ["免费试用", "解析速度", "高性能渲染", "安全高效"],
      exePageUrl: "local-exe.html",
      isHint: false,
      isUserLiheight: false,
      arrow: true,
      visible: false,
      adObj: {},
      arrowOperate: false,
      defultHeader: require("@/assets/avatar_default.png"),
      ifShowLogin: false,
      searchStr: "",
      scrollFlag: false,
      user: getUserInfo(),
      screenWidth: 0,
      opereteIndex: -1,
      operetelist: [
        { name: "关于超真云", path: "/about" },
        { name: "云手册", path: "/case" },
        { name: "云引擎", path: "/engine" },
        { name: "试用云引擎", path: "/trial-mobile" },
        // { name: "开放平台", path: "/creator" }
        // { name: "会员", path: "" }, //member
      ],
      paths: ["/case", "/engine", "/creator", "/about"],
      // 是否存在未读信息
      isDot: false,
      //尝试苹果系统下使用2倍图以防止模糊问题
      appLogo: isAppleDevice() ? require("@/assets/newImg/res-czy3d/logo-czy3d-b.svg") : require("@/assets/newImg/res-czy3d/logo-czy3d-b.svg"),
      // 通知时间 看是不是最新时间
      noticeUpdateTime: localStorage.getItem("updateTime") ?? -1,
      // 是否显示比赛通知
      isShowContest: true,
      // 通知信息
      noticeData: {},
      robolinkDownloadUrl: ''
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    hideHeader() {
      return (this.$route.meta.hideHeader || this.$route.query.hideHeader === "true") ?? window.top !== window; //内嵌时默认不显示页头
    },
    hideMobileHeader() {
      return this.$route.meta?.hideMobileHeader ?? false;
    },
  },
  watch: {
    $route(to, from) {
      this.operetelist.forEach((item, i) => {
        if (item.path == to.path) {
          this.opereteIndex = i;
          this.isUserLiheight = false;
        }
      });
      // 未找到则返回 -1
      if (this.paths.indexOf(to.path) == -1) {
        this.opereteIndex = -1;
      }

      if (to.path == from.path && from.path != "/") {
        return;
      }
    },
    searchStr(val) {
      this.$store.commit("changeSearchStr", val);
    },
    userInfo: {
      handler(newVal) {
        this.user = newVal;
      },
    },
  },
  async mounted() {
    let { data } = await getAdList('ad_home')
    console.log('广告页', data)
    if (data.length > 0) {
      this.adObj = data[0]
    }

    this.screenWidth = document.documentElement.clientWidth;
    this.user = getUserInfo();
    this.$store.dispatch("getuseAppList");
    this.getsysNoticeList();
    this.getRoboLinkDownloadUrl()
    let that = this;
    let cad = localStorage.getItem('isShowCad')
    if (cad) {
      cad = JSON.parse(cad);
      that.isShowCad = JSON.parse(cad);
    } else {
      that.isShowCad = true
    }
    setTimeout(function () {  // 倒计时
      that.isShowCad = cad ?? false;
    }, 3000);
  },
  methods: {
    goLinkUrl(link) {
      window.open(link, '_blank')
    },
    getRoboLinkDownloadUrl() {
      sysConfigByKey("sys.robolink.downurl.windows").then((res) => {

        this.robolinkDownloadUrl = res.data;
      });
    },
    getDownload(text) {
      console.log(11);

      try {
        TDAPP.onEvent(text);
      } catch (error) { }
    },
    // 获取通知信息
    async getsysNoticeList() {
      // const res = await sysNotice();
      // if (res.data.length) {
      //   this.noticeData = res.data[0];
      //   if (this.noticeUpdateTime == -1) {
      //     this.isShowContest = true;
      //     // 通知时间 看是不是最新时间 是最新时间则显示通知
      //   } else if (this.noticeUpdateTime !== res.data[0]?.updateTime) {
      //     this.isShowContest = true;
      //   } else {
      //     this.isShowContest = false;
      //     this.$refs.headNavigator.style.height = "60px";
      //   }
      // }
    },
    wakeUpExe() {
      var customProtocol = "virtualcourse.2099d29708328a80758b574d76551ea6:";
      var operation = "add"; // 设置要执行的操作
      var operand = "10"; // 设置操作数
      // 构建带有参数的URL
      var calculatorURL = customProtocol + "//" + "?operation=" + operation + "&operand=" + operand;
      var iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = calculatorURL;
      document.body.appendChild(iframe);

      setTimeout(function () {
        document.body.removeChild(iframe);
      }, 500);
    },
    // 获取用户信息
    getUserInfo() {
      let user = getUserInfo();
      if (user) {
        this.tongzhi();
      }
      return user;
      console.log(res);
    },
    // 云手册定制页面
    goCustomMade() {
      this.isHint = true;
      this.$refs.nav.navIndex = -1;
      window.open("https://xr.czy3d.com/");
      // this.$router.push('/customMade')
    },
    // 试用云引擎

    useEngine(type) {
      try {
        type == 1 ? TDAPP.onEvent("登录") : TDAPP.onEvent(!this.user ? "免费试用" : "创建应用");
      } catch (error) { }
      this.$store.commit("chanPageUrl", {
        path: "/createProject",
        goPageStatus: 0,
      });
      this.goEnginePage();
    },
    closeCad() {
      localStorage.setItem('isShowCad', false)
      this.isShowCad = false
    },
    @requireAuthBefore()
goEnginePage() {
  this.$router.push("/createProject");
  this.$nextTick(function () {
    console.log(11111111111111111);
    const el = document.getElementById("box-0");
    if (el) {
      // 使用window.scrollTo实现平滑滚动
      window.scrollTo({
        top: el.offsetTop - 150,
        behavior: "smooth",
      });
      el.classList.add("breathing-text");
      setTimeout(() => {
        el.classList.remove("breathing-text");
      }, 2000);
    }
  });
},
tongzhi() {
  let that = this;
  check().then((res) => {
    if (res.code == 200) {
      if (res.data.notificationUnreadCount) {
        that.isDot = true;
      }
    }
  });
},
goMainPage() {
  this.$router.push("/");
},
hidePop() {
  this.visible = !this.visible;
},
touchstart(e) {
  if (this.visible) {
    let that = this;
    this.visible = false;
    e.preventDefault();
  }
},
afterEnter() {
  let that = this;
  document.addEventListener(
    "click",
    function (e) {
      that.touchstart(e);
    },
    { passive: false }
  );
},
loginOrOut() {
  this.$refs.headPopover.showPopper = false;
  if (this.user) {
    this.user = null;
    removeUserInfo();

    if (this.$route.meta.requiresAuth) {
      this.$router.push("/");
    }
  } else {
    this.$emit("triggerLogin");
    if (this.screenWidth > 700) {
      this.$store.commit("changeLoginDialogVisible", 7);
    } else {
      this.$router.push("/loginMobile");
    }
  }
},
goPage(index) {
  this.opereteIndex = index;
  this.isUserLiheight = false;
  this.$refs.headPopover.showPopper = false;
  this.$router.push({ path: this.operetelist[index].path });
},
goUserPc() {
  this.$refs.headPopover.showPopper = false;
  if (this.user) {
    this.$router.push("/userPage/" + this.user.userId);
  }
},
goUserInfoAct() {
  this.$refs.headPopover.showPopper = false;
  if (this.user) {
    this.$router.push({
      path: "/M_spaceData",
      query: {
        spaceId: this.user.mainSpace.id,
        spaceName: this.user.mainSpace.spaceName,
      },
    });
    this.isUserLiheight = true;
    this.opereteIndex = -1;
  }
},
handleScroll() {
  // 获取页面页面的滚动高度
  let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
  // 获取页面的可视高度
  if (this.$route.path == "/") {
    if (scrollTop >= 650) {
      this.scrollFlag = true;
      this.$refs.headNavigator.style.opacity = 0.75;
      this.$refs.headNavigator.style.background = "#000000";
    } else {
      this.$refs.headNavigator.style.opacity = 1;
      if (this.$route.path == "/") {
        this.$refs.headNavigator.style.background = "linear-gradient(to right, #000, #414142, #000)";
      } else {
        this.$refs.headNavigator.style.background = "#000000";
      }
    }
  } else {
    this.$refs.headNavigator.style.background = "rgba(0, 0, 0, 0.5) ";
    if (scrollTop >= 100) {
      this.scrollFlag = true;
      // this.$refs.headNavigator.style.opacity = 0.5;
      // this.$refs.headNavigator.style.background = "#000000";
    } else {
      this.$refs.headNavigator.style.opacity = 1;
    }
  }
  this.touchstart();
},
// 用户手动关闭通知逻辑
closeContest() {
  this.isShowContest = false;
  localStorage.setItem("updateTime", this.noticeData.updateTime);
  this.$refs.headNavigator.style.height = "60px";
},
  },
};
</script>

<style lang="scss">
.noun-download {
  cursor: pointer;
  margin-right: 10px;
  vertical-align: middle;
}

.download-content {
  width: 350px;
  height: 250px;
  padding-top: 30px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(125, 125, 125, 0.15);

  >div {
    padding: 10px 10px 10px 20px;
    height: 55px;
    display: flex;
    justify-content: space-between;
    margin: 0 14px;
    align-items: center;

    &:hover {
      border-radius: 8px;
      background: #f6f7f7;
    }
  }

  .left-content {
    width: 60%;
    display: flex;
    align-items: center;

    img {
      vertical-align: middle;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      margin-right: 22px;
    }
  }

  .download-btn {
    width: 56px;
    height: 30px;
    color: #ff4500;
    line-height: 30px;
    text-align: center;
    font-family: "Source Han Sans CN";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    border-radius: 6px;
    background: rgba(255, 69, 0, 0.1);
    cursor: pointer;

    a {
      color: #ff4500;
      text-decoration: none;
    }
  }
}

.mobile-header {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

.headerDiv {
  // background: linear-gradient(to right, #000, #414142, #000)
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  height: 60px;

  &-showContest {
    height: 105px !important;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

  .contest {
    position: relative;
    height: 45px;
    line-height: 30px;
    background: url(../../assets/head-bg1.png) no-repeat;

    text-decoration: underline;
    width: 100%;
    color: #333;
    cursor: pointer;
    background-size: 100% 100%;

    .el-icon-close {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translatey(-50%);
      cursor: pointer;
    }

    .head-vip {
      height: 100%;
    }
  }
}

.add-project {
  background: rgba(51, 51, 51, 1) !important;
  border: 0px solid !important;
  width: 360px !important;
  min-height: 260px;
  box-shadow: 2px 10px 12px 0px rgba(0, 0, 0, 0.25);
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  line-height: 24px;
  border-radius: 10px !important;

  .popper__arrow {
    display: none !important;
  }
}
</style>
<style lang="scss" scoped>
.cad-card {
  position: absolute;
  top: 26px;
  right: 0;
  width: 540px;
  height: 338px;
  background: url(../../assets/cad-card-bg.png) no-repeat 50% / cover;
  text-align: center;

  .x {
    position: absolute;
    right: 16px;
    top: 22px;
    cursor: pointer;
  }

  .cad-card-title {
    margin-top: 38px;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 28px;
    letter-spacing: 1px;
    margin-bottom: 15px;

  }

  .icon-content {
    width: 440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 24px;

    .icon-item {


      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        display: inline-block;
        margin: 0 12px 0 8px;
        font-weight: 500;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 12px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      .icon-check {
        width: 12px;
        height: 12px;
      }
    }
  }

  .cad-box {
    margin: 0 auto;
    width: 488px;
    height: 196px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px 6px 6px 6px;
    padding: 8px;
    display: flex;
    justify-content: space-between;

    .cad-left {
      width: 334px;
      height: 180px;
      border-radius: 4px;
      background: url(@/page/home/commercialization/cad/static/wx-file1.png) no-repeat 50% / cover;

    }
  }

  .wx-code {
    width: 126px;

    .qr-code {
      width: 112px;
      height: 112px;
      padding: 10px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px 10px 10px 10px;
      margin-right: 13px;
      margin-bottom: 10px;
    }

    .wx-desc {

      font-weight: 400;
      font-size: 10px;
      color: rgba(0, 0, 0, 0.6);
      line-height: 12px;
      margin-bottom: 10px;
      text-align: center;
      font-style: normal;
      text-transform: none;

    }

    .wx-title {

      font-weight: 500;
      font-size: 12px;
      color: #4096FF;
      line-height: 14px;
      text-align: center;
      font-style: normal;
      text-transform: none;

    }
  }
}

.vip-avatar {
  position: absolute;
  position: absolute;
  right: 137px;
  z-index: 9;
  top: -6px;
}

.btn-bg {
  width: 120px !important;

  background-image: url(../../assets/newImg/tryout1.svg);
  background-repeat: no-repeat;
  color: #fff;
  font-size: 14px;
  height: 40px !important;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-loginBg {
  width: 85px;
  height: 40px;
  background-image: url(../../assets/newImg/login-bg.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #ff4500;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.us-lang {
  font-size: 20px !important;
  margin: 5px 0;

  .btn1 {
    display: flex;
    align-items: center;
    padding-left: 15px;
    height: 30px;
    width: calc(100% - 10px);
    margin-left: 5px;
    text-align: left;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    color: #555555;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);

    &:hover {
      border-radius: 6px;
      background: rgba(255, 102, 0, 1);
    }
  }
}

.userAvatar {
  &-left {
    color: #000;
    text-align: right;
    margin-right: 6px;
    // width: 90px;

    &-userName {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 1);
      // width: 90px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-spaceName {
      font-size: 14px;
      color: #555555;
      // width: 90px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.avatar {
  margin-right: 18px;
}

.PayFailureBanner {
  width: 100%;
}

.hint {
  width: 90px;
  position: absolute;
  bottom: 18px;
  height: 5px;
  background-color: white;
  border-radius: 20px 20px 20px 20px;
  // background: #f9a852;
  // border-radius: 20px 20px 0px 0px;
}

.custom-img {
  margin-right: 8px;
  cursor: pointer;
  width: 85px;
  height: 40px;
  // margin-left: 70px;
}

.custom-made {
  cursor: pointer;
  margin-right: 17px;
  width: 100px;
  height: 36px;
  border: 1px solid #ffffff;
  border-radius: 18px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.freeDownload {
  width: 130px;
  background-color: #ffffff;
  color: #f57f22;
  // font-weight: 600;
}

@media screen and (max-width: 750px) {
  .custom-made {
    display: none;
  }
}
</style>
<style>
.loginPop {
  /* background: rgba(51, 51, 51, 1) !important; */
  box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.2) !important;
  border-radius: 10px !important;
  border: 0px solid !important;

  z-index: 9999 !important;
}
</style>
