import request from "@/static/js/request";

export function insertAi(data) {
  return request({
    url: "/aiproject",
    method: "post",
    data: data,
  });
}
// 修改
export function updateAi(data) {
  return request({
    url: "/aiproject",
    method: "put",
    data: data,
  });
}
export function generateKnwoledge(data) {
  return request({
    url: "/aiproject/generateKnowledge",
    method: "post",
    data: data,
  });
}

let baseUrl = "https://api.czy3d.com/czy-model-library";
// 获取平台模型类别
export function getModelCategory() {
  return request({
    url: "/standardLibrary/classifyList",
    method: "get",
    baseURL: baseUrl,
  });
}

// 获取平台模型列表
export function getModelList(params) {
  return request({
    url: "/standardLibrary/menuSourceList",
    method: "get",
    params,
    baseURL: baseUrl,
  });
}

// 获取模型详情
export function getModelDetail(sourceId) {
  return request({
    url: "/standardLibrary/modelList",
    method: "get",
    params: { sourceId },
    baseURL: baseUrl,
  });
}

// 获取模型路径
export function getModelPath(modelId) {
  return request({
    url: "/standardLibrary/modelPathInfo",
    method: "get",
    params: { modelId },
    baseURL: baseUrl,
  });
}
// 2d转3d 生成模型
export function instanceMesh_mtl(data) {
  return request({
    url: "/ai/InstanceMesh_mtl",
    method: "post",
    data: data,
    ignoreLoading: true,
  });
}

// 广告相关
export function getAdList(type) {
  return request({
    url: "/promotion/ad/list",
    method: "get",
    params: { type: type },
  });
}
export function changestatus(data) {
  return request({
    url: "/knowledge/changeStatus",
    method: "POST",
    params: data,
  });
}
// 根据工程id查询作品详情

export function queryAiId(id) {
  return request({
    url: "/aiproject/" + id,
    method: "get",
  });
}
// 查询作品详情
export function worksDetailInfo(workId) {
  return request({
    url: `/knowledge/verifyVisibility?knowledgeId=${workId}`,
    method: "get",
  });
}

export function pvRecord(id) {
  return request({
    url: `/promotion/ad/pvRecord/${id}`,
    method: "POST",
  });
}
